<template>
    <v-app>
        <v-row>
            <v-col cols="3">
                <v-expansion-panels accordion multiple v-if="lesson_plan_topics.length" v-model="expanded">
                    <v-stepper
                            v-model="completed_unit"
                            dense

                    >
                        <v-stepper-step
                                v-for="(unit,count) in lesson_plan_topics"
                                :key="`topicKey-${count}-${unit.id}`"
                                :step="count+1"
                                :complete="completed_topic_ids.includes(unit.id)"
                                :color="(completed_topic_ids.includes(unit.id)) ? 'green' : 'blue'"
                                dense

                        >
                            <v-expansion-panel
                            >
                                <v-expansion-panel-header>
                                    {{unit.title}}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-stepper
                                            v-if="unit.lessons && unit.lessons.length"
                                            v-model="completed_step"
                                            vertical
                                            non-linear
                                    >
                                        <v-stepper-step

                                                v-for="(lesson,index) in unit.lessons" :key="`lesson-${index}-${lesson.title}`"
                                                :step="stepSetter(count+1,index+1)"
                                                :complete="completed_lesson_ids.includes(lesson.id)"
                                                :color="(completed_lesson_ids.includes(lesson.id)) ? 'green' : 'blue'"
                                                :editable="stepSetter(count+1,index+1) == completed_unit"
                                        >
                                            {{lesson.title}}
                                            <!--                                    <small>Summarize if needed</small>-->
                                        </v-stepper-step>
                                    </v-stepper>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <!--                                    <small>Summarize if needed</small>-->
                        </v-stepper-step>
                    </v-stepper>

                </v-expansion-panels>
            </v-col>
            <v-col cols="9">
                <v-stepper
                        v-for="(unit,content_index) in lesson_plan_topics" :key="`unit-detail${unit.id}`"
                        v-model="completed_unit"

                >
                    <!--                    {{content_index+1}}-->
                    <!--                    {{completed_unit}}-->
                    <!--                    {{unit.title}}-->
                    <template v-show="(content_index+1) == completed_unit">
                        <v-stepper-content :step="content_index+1">
                            {{unit.title}}
                            <v-stepper
                                    v-for="(lesson,content_lesson_index) in unit.lessons" :key="`lesson-detail-${content_lesson_index}-${lesson.title}`"
                                    v-model="completed_step"

                            >
                                <template>
                                    <!--                                    {{content_index+1}}-->
                                    <!--                                    {{content_lesson_index+1}}-->
                                    <!--                                    {{stepSetter(content_index+1,content_lesson_index+1)}}-->
                                    <v-stepper-content
                                            :step="stepSetter(content_index+1,content_lesson_index+1)"
                                    >
                                            <h2> {{lesson.title}} </h2>

                                        <strong>Unit {{getLessonCount()}} / {{unit.lessons.length}}</strong>
                                        <p v-html="lesson.description"></p>
                                        <div class="d-flex justify-space-between">
                                            <v-btn v-if="content_lesson_index!=0" @click="perviousLesson">
                                                Previous
                                            </v-btn>
                                            <v-btn v-if="completed_unit!=1 && content_lesson_index==0"
                                                   @click="previousUnit">
                                                Previous Unit
                                            </v-btn>
                                            <v-btn v-if="getLessonCount()<unit.lessons.length" @click="nextLesson">
                                                Next
                                            </v-btn>
                                            <div v-else>
                                                <v-btn @click="nextUnit">
                                                    Next Unit
                                                </v-btn>
                                            </div>
                                        </div>


                                    </v-stepper-content>
                                </template>

                            </v-stepper>


                            <div class="card-body" v-if="unit.activity_resources.length">
                                <v-divider></v-divider>
                                <h3> Unit Resources</h3>
                                <v-list three-line>
                                    <template v-for="(item, index) in unit.activity_resources">
                                        <v-list-item dense
                                                     :key="item.id"
                                        >
                                            <v-list-item-avatar rounded>
                                                <v-img :src="item.activity.full_image_path"></v-img>
                                            </v-list-item-avatar>

                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <a v-if="checkType(item.activity.type) =='url'" :href="item.url"
                                                       target="_blank">
                                                        <strong> {{item.title}}</strong>
                                                    </a>
                                                    <strong v-else>
                                                        {{item.title}}

                                                    </strong>
                                                </v-list-item-title>
                                                <v-list-item-content>
<!--                                                    <pre>-->
<!--                                                        {{item}}-->
<!--                                                    </pre>-->
                                                    <router-link
                                                            v-if="item.type==='quiz'"
                                                            :to="{ name: 'quiz-prepare', params: { quiz_id: item.quiz.id }}"
                                                            custom
                                                    >
                                                        Start
                                                    </router-link>
                                                    <a v-else-if="item.type!='assignment'" :href="item.path.real"
                                                       target="_blank">download</a>

                                                    <router-link
                                                            :to="{ name: 'assignment-summary', params: { assignment_id: item.assignment.id }}"
                                                            custom
                                                            v-else
                                                    >
                                                        View Assignment
                                                    </router-link>
                                                </v-list-item-content>
                                                <v-list-item-subtitle v-if="item.type!='assignment'"
                                                                      class="list-content-desc"
                                                                      v-html="item.description"></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-list>

                            </div>
                        </v-stepper-content>
                    </template>

                </v-stepper>
            </v-col>
        </v-row>


    </v-app>
</template>

<script>
    import {LessonPlannerMixin} from "@/mixins/LessonPlannerMixin";

    export default {
        mixins: [LessonPlannerMixin],
        name: "summary",
        computed: {
            lesson_id() {
                return this.$route.params.lesson_plan_id;
            },
            filterData() {
                return this.lesson_plan_topics.map(unit => {
                    return {lesson: unit.lessons};
                });
            }
        },
        data() {
            return {
                lesson_plan_topics: [],
                expanded: [0],
                completed_step: 1.1,
                completed_unit: 1,
                items: 5,
            }
        },
        mounted() {
            this.getSingleLesson(this.lesson_id);

        }, methods: {
            nextLesson() {
                this.completed_step = this.completed_step.toString();
                let [unit, lesson] = this.completed_step.split(".");
                lesson++;
                this.completed_step = parseFloat(`${unit}.${lesson}`);
            },
            perviousLesson() {
                this.completed_step = this.completed_step.toString();
                let [unit, lesson] = this.completed_step.split(".");
                lesson--;
                this.completed_step = parseFloat(`${unit}.${lesson}`);
            },

            nextUnit() {
                this.expanded.splice(this.expanded.indexOf(this.completed_unit - 1), 1);
                this.expanded.push(this.completed_unit)
                this.completed_unit++;
                this.completed_step = parseFloat(`${this.completed_unit}.1`);
            },
            previousUnit() {
                this.expanded.splice(this.expanded.indexOf(this.completed_unit - 1), 1);
                this.expanded.push(this.completed_unit)
                this.completed_unit--;
                this.completed_step = parseFloat(`${this.completed_unit}.1`);
            },
            stepSetter(count, index = null) {
                return parseFloat(`${count}.${index}`);
            },
            getLessonCount() {
                return this.completed_step.toString().split('.')[1];
            },
            updateLessonPlanCompletion() {
                this.$confirm({
                    message: `Are you sure?`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            if (this.completed_topic_ids && this.completed_topic_ids.length > 1) {
                                this.completed_topic_ids = this.completed_topic_ids.join(',')
                            }
                            if (this.completed_lesson_ids && this.completed_lesson_ids.length > 1) {
                                this.completed_lesson_ids = this.completed_lesson_ids.join(',')
                            }
                            this.__updateLessonPlan(this.lesson_id);
                        }
                    }
                });
            },


        },

    }
</script>

<style scoped>
    .v-stepper__step {
        padding: 5px 5px !important;
        align-items: baseline !important;
    }

    .v-expansion-panel-header {
        padding: 12px 8px !important;
    }

    .v-stepper {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }

    .v-stepper--vertical {
        padding-bottom: 0 !important;
    }

    .v-expansion-panel-content .v-expansion-panel-content__wrap {
        padding: 0 0px 0px !important;
        background: #9f3a38 !important;
    }
</style>